
namespace ZBattle {

    export class ZGame {

        public hide(element: HTMLElement): void {
            if (element === null) throw Error('element cannot be null');
            element.hidden = true;
        };

        public hideAll(element: HTMLElement, classNames: string): void {
            if (element === null) throw Error('element cannot be null');
            const children = <HTMLElement[]><any>element.getElementsByClassName(classNames);
            for (let child of children) {
                child.hidden = true;
            }
        };

        public setChecked(input: HTMLInputElement, checked: boolean): void {
            if (input === null) throw Error('input cannot be null');
            input.checked = checked;
        };

        public setClassName(element: HTMLElement, className: string): void {
            if (element === null) throw Error('element cannot be null');
            if (className === null) throw Error('className cannot be null');
            element.className = className;
        };

        public setDisabled(input: HTMLInputElement, disabled: boolean): void {
            if (input === null) throw Error('input cannot be null');
            input.disabled = disabled;
        };

        public setInnerText(element: HTMLElement, innerText: string): void {
            if (element === null) throw Error('element cannot be null');
            if (innerText === null) throw Error('innerText cannot be null');
            element.innerText = innerText;
        };

        public show(element: HTMLElement): void {
            if (element === null) throw Error('element cannot be null');
            element.hidden = false;
        };

        public toggle(element: HTMLElement): void {
            if (element === null) throw Error('element cannot be null');
            element.hidden = !element.hidden;
        };

    }

}

window['ZGame'] = new ZBattle.ZGame();
